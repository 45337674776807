import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { FilledButton } from '@jsluna/button';
import { Display2 } from '@jsluna/typography';
import { TextInputField } from '@jsluna/form';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Card = makeShortcode("Card");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-simple-words"
            }}>{`Use simple words`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#speak-the-language-of-your-audience"
            }}>{`Speak the language of your audience`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-consistent-language"
            }}>{`Use consistent language`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-direct-language"
            }}>{`Use direct language`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "use-simple-words",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-simple-words",
        "aria-label": "use simple words permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Use simple words`}</h2>
    <p>{`The longer or more complicated words we use, the more people will struggle to understand and skip over our words. So we need to choose short, familiar words throughout our content.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
  <div>Turn off delivery tracking</div>
  <div>Set up your account</div>
  <div>Edit your payment details</div>
  </Do>
  <Dont mdxType="Dont">
  <div>Deactivate delivery tracking</div>
  <div>Configure your account information</div>
  <div>Modify your payment details</div>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "speak-the-language-of-your-audience",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#speak-the-language-of-your-audience",
        "aria-label": "speak the language of your audience permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Speak the language of your audience`}</h2>
    <p>{`Your audience is unlikely to think about things the same way we do internally, so research the terms you’re using with your audience.`}</p>
    <p>{`Your content will be easier to engage with if it uses terms your audience is already familiar with, rather than having to introduce new terms.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "use-consistent-language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-consistent-language",
        "aria-label": "use consistent language permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Use consistent language`}</h2>
    <p>{`Inconsistency in content creates confusion. So once you choose the words you're going to use to describe something, use those same words throughout the entirety of your content. `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>
      Keep the words or phrases you use for actions consistent throughout.
    </p>
    <Card className="ln-u-border" mdxType="Card">
      <p><strong>Log in</strong></p>
      <p>Log in to your account to track your orders and manage your details.</p>
      <FilledButton fullWidth mdxType="FilledButton">Log in</FilledButton>
    </Card>
  </Do>
  <Dont mdxType="Dont">
    <p>
      Being inconsistent with words or phrases is confusing to people.
    </p>
    <Card className="ln-u-border" mdxType="Card">
      <p><strong>Log in</strong></p>
      <p>Sign up to your account to track your orders and manage your details.</p>
      <FilledButton fullWidth mdxType="FilledButton">Sign in</FilledButton>
    </Card>
  </Dont>
    </Guideline>
    <p>{`Always try to address people as 'you' or 'your'. Avoid being inconsistent with the way you address people, especially in the same piece of content as it's much harder to read. `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Change your address details by logging into your account</Do>
  <Dont mdxType="Dont">Change your address details by logging into My Account</Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "use-direct-language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-direct-language",
        "aria-label": "use direct language permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Use direct language`}</h2>
    <p>{`The easiest way to be direct with people is to use the active voice, which makes your sentences shorter and easier to read. It also makes clear who did what and that builds trust with our audience. `}</p>
    <p>{`With active voice, the subject performs the action ("you designed the content"). With passive voice, the subject receives the action ("the content was designed by you"). `}</p>
    <Guideline mdxType="Guideline">
  <Do title="Active voice" mdxType="Do">
     <div>We’re investigating the problem</div>
     <div>You can change your order at any time</div>
     <div>We've sent you a refund</div>
  </Do>
  <Dont title="Passive voice" mdxType="Dont">
     <div>The problem is being investigated</div>
     <div>Your order can be changed at any time</div>
     <div>Your refund was sent</div>
  </Dont>
    </Guideline>
    <p>{`We can use the passive voice every now and again when we want to put to be direct about the action being taken, rather than who did the action. So a good use of the passive voice is when we're confirming an action.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Passive voice" mdxType="Do">
    <div>Password updated</div>
    <div>Changes saved</div>
  </Do>
  <Dont title="Active voice" mdxType="Dont">
    <div>You updated your password</div>
    <div>We've saved your changes</div>
  </Dont>
    </Guideline>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help answer any questions around content or get involved with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      